import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["User", "Task", "Profile", "leaderBoard", "Wallet"], // automatic-data fetching
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLoginUser: builder.query({
      query: () => {
        return {
          url: "/common/fetch_login_user",
          method: "GET",
        };
      },
      providesTags: ["User"], // automatic-data fetching
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/secure/delete_account",
        method: "PUT",
      }),
      invalidatesTags: ["delete"],
    }),
    getValidateEmail: builder.query({
      query: (email) => {
        return {
          url: `/validate_email?email=${email}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addGoogleLogin: builder.mutation({
      // user register
      query: (body) => ({
        url: "/google_login",
        method: "POST",
        body: body,
      }),
    }),
    addLoginWithMobile: builder.mutation({
      query: (body) => ({
        url: "/login-with-mobile",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    findMobileNumberOnDatabase: builder.query({
      query: (mobile) => {
        return {
          url: `/find_mobile_no_in_database?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["Auth"],
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send-otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    checkOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    forgetPasswordWithMobile: builder.mutation({
      query: (body) => ({
        url: "/forget_password_with_mobile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    sendEmailOtp: builder.mutation({
      query: (body) => {
        return {
          url: "/send_email_otp",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Auth"],
    }),
    sendEmailForgotOtp: builder.mutation({
      query: (body) => ({
        url: "/forgot_password",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    checkEmailOtpMatch: builder.mutation({
      query: (body) => ({
        url: "/check-email-otp-match",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    addResetPass: builder.mutation({
      query: (body) => ({
        url: `/reset_password`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"], // automatic-data fetching
    }),
    getManageSlider: builder.query({
      query: () => "/secure/get-manage-slider",
      providesTags: ["Task"],
    }),
    myProfile: builder.query({
      query: () => "/secure/profile",
      providesTags: ["Profile"],
    }),
    claimBtn: builder.query({
      query: () => "/secure/claim-button",
      providesTags: ["leaderBoard"],
    }),
    getTotalPoints: builder.query({
      query: () => "/secure/get_total_points",
      providesTags: ["leaderBoard"],
    }),
    getWallet: builder.query({
      query: () => "/secure/get-wallet",
      providesTags: ["Wallet"],
    }),
    addBank: builder.mutation({
      query: (body) => ({
        url: "/secure/create_payment_details",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    fetchBank: builder.query({
      query: () => ({
        url: "/secure/fetch_bank",
        method: "GET",
      }),
      providesTags: ["Bank"],
    }),
    deleteBankCard: builder.mutation({
      query: (body) => ({
        url: "/secure/delete_payment_details",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Bank"],
    }),
    deleteBank: builder.mutation({
      query: (bankId) => ({
        url: "/secure/delete_bank",
        method: "PUT",
        body: { id: bankId },
      }),
      invalidatesTags: ["Bank"],
    }),
    updateBank: builder.mutation({
      query: (body) => ({
        url: "/secure/update_payment_details",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    addUPI: builder.mutation({
      query: (body) => ({
        url: "/secure/addUpi",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Bank"],
    }),
    dailyLeaderBoard: builder.query({
      query: ({page, limit}) => `/secure/daily_leaderboard_live_data?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    weeklyLeaderBoard: builder.query({
      query: ({page, limit}) => `/secure/weekly_leaderboard_live_data?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    monthlyLeaderBoard: builder.query({
      query: ({page, limit}) => `/secure/monthly_leaderboard_live_data?page=${page}&limit=${limit}`,
      providesTags: ["leaderBoard"],
    }),
    getValidateSponsorId: builder.query({
      query: (sponsorid) => {
        return {
          url: `/sponsorid?username=${sponsorid}`,
          method: "GET",
        };
      },
    }),
    editImage: builder.mutation({
      query: (body) => ({
        url: "/secure/change_profile_pic",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/secure/profile",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    allLeaderBoardHistory: builder.query({
      query: () => "/secure/all_leaderboard_history",
      providesTags: ["leaderBoard"],
    }),
    myTotalReferrals: builder.query({
      query: ({ username }) => `/secure/total-referrals/${username}`,
      providesTags: ["Team"],
    }),
    selfEarning: builder.query({
      query: ({ page, limit }) =>
        `/secure/get-self-earning-history?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    referralEarning: builder.query({
      query: ({ page, limit }) =>
        `/secure/get-referral-earning-history?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    rewardIncome: builder.query({
      query: ({ page, limit }) =>
        `/secure/fetch_Reward_income?page=${page}&limit=${limit}`,
      providesTags: ["Income"],
    }),
    changeEmail: builder.mutation({
      query: (body) => ({
        url: "/secure/change_email",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),

    // OTP
    sendOtpSecure: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/send-update-OTP",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    verifyMobile: builder.mutation({
      query: (body) => {
        return {
          url: "/secure/verify-mobile",
          method: "PUT",
          body: body,
        };
      },
      providesTags: ["Profile"],
    }),
    setDOB: builder.mutation({
      query: (body) => ({
        url: "/secure/set-dob",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getValidateUsername: builder.query({
      query: (username) => {
        return {
          url: `/validate_username?username=${username}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    getValidateMobile: builder.query({
      query: (mobile) => {
        return {
          url: `/validate_mobile?mobile=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["UserValidate"],
    }),
    addMobileRegister: builder.mutation({
      query: (body) => ({
        url: "/mobile_register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getLeaderBoardUserInfo: builder.query({
      query: (username) =>
        `/secure/get-leader-board-user-info?username=${username}`,
      providesTags: ["leaderBoard"],
    }),
    addKyc: builder.mutation({
      query: (body) => ({
        url: "/secure/create_KYC", // Adjust the URL for KYC submission
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Kyc"], // Invalidate KYC cache tags if necessary
    }),
    uploadKYC: builder.mutation({
      query: (body) => ({
        url: "/secure/create_KYC",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    getKyc: builder.query({
      query: () => ({
        url: "/secure/get_KYC",
        method: "GET",
      }),
      providesTags: ["Kyc"],
    }),
    getVerifiedKyc: builder.query({
      query: () => "/secure/get_Verified_KYC",
      method: "GET",
      providesTags: ["kyc"],
    }),
    getAvailableSocialMediaTasks: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) =>
        `/secure/get-available-social-media-tasks-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
      providesTags: ["Available Social Media Tasks"],
    }),
    getAllMySMTHistory: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) =>
        `/secure/get-all-my-social-media-task-history-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
      providesTags: ["Task"],
    }),
    getSubmittedSMT: builder.query({
      query: ({ page, limit, task_profile, task_sub_profile }) =>
        `/secure/get-submitted-social-media-tasks?page=${page}&limit=${limit}&task_profile=${task_profile}&task_sub_profile=${task_sub_profile}`,
      providesTags: ["Task"],
    }),
    submitPremiumPaymentProof: builder.mutation({
      query: (data) => ({
        url: "/secure/submit-premium-payment-proof",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumPurchase"],
    }),
    submitPremiumPlusPaymentProof: builder.mutation({
      query: (data) => ({
        url: "/secure/submit-premium-plus-payment-proof",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumplusPurchase"],
    }),
    submitSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/submit-social-media-task",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Task"],
    }),
    // getTotalAvailableSMT: builder.query({
    //   query: ({ page, limit, task_profile }) =>
    //     `/secure/get-available-social-media-tasks?page=${page}&limit=${limit}&task_profile=${task_profile}`,
    //   providesTags: ["Total Available Social Media Tasks"],
    // }),
    getTotalAvailableSMT: builder.query({
      query: ({ page, limit, task_profile, searchTerm }) =>
        `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&searchTerm=${searchTerm}`,
      providesTags: ["Total Available Social Media Tasks"],
    }),
    getPayment: builder.query({
      query: () => {
        return {
          url: "/secure/payUsingPhonePe",
          method: "GET",
        };
      },
      providesTags: ["Payment"],
    }),
    getPaymentInfo: builder.query({
      query: () => ({
        url: "/common/get_payment_info",
        method: "GET", // Use GET since this is fetching data
      }),
      providesTags: ["paymentInfo"], // Add tags for caching and invalidation
    }),
    submitPremiumPurchase: builder.mutation({
      query: (data) => ({
        url: "/secure/submit_premium",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumPurchase"],
    }),
    getMyReferrals: builder.query({
      query: ({ page, limit, searchValue }) =>
        `/secure/referrals?page=${page}&limit=${limit}&searchValue=${searchValue}`,
      providesTags: ["Referrals"],
    }),
    GetNotifications: builder.query({
      query: (type) => ({
        url: `/secure/getNotification?type=${type}`,
        method: "GET",
      }),
      providesTags: ["Notifications"], // optional, useful if notifications update frequently
    }),
    UpdateNotificationStatus: builder.mutation({
      query: (body) => ({
        url: "/secure/updateNotificationStatus",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["Notifications"],
    }),
    getCustomNewsForAll: builder.query({
      query: () => "/secure/getCustomNewsForAll",
      providesTags: ["Notifications"],
    }),
    getlatestNewsForUser: builder.query({
      query: () => "/secure/get_latest_news_user",
      providesTags: ["Notifications"],
    }),
    claimNow: builder.mutation({
      query: (body) => ({
        url: "/secure/claim-now",
        method: "POST",
        body,
      }),
      invalidatesTags: ["leaderBoard"],
    }),
    getMyPointHistory: builder.query({
      query: ({ page, limit, type }) =>
        `/secure/get_user_all_point_history?page=${page}&limit=${limit}&type=${type}`,
      providesTags: ["leaderBoard"],
    }),
    getRedeemConversionRatio: builder.query({
      query: () => "/secure/get_redeem_conversion_ratio",
      providesTags: ["redeem"],
    }),
    submitRedeem: builder.mutation({
      query: (data) => ({
        url: "/secure/submit_redeem",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["redeem"],
    }),
    getAllMysteryBox: builder.query({
      query: () => "/secure/get-all-mistbow",
      providesTags: ["leaderBoard"],
    }),
    getMinimumWithdrawAmount: builder.query({
      query: () => "/secure/get-minimum-withdraw-amount",
      providesTags: ["Withdraw"],
    }),
    getWithdrawChargeAmount: builder.query({
      query: () => "/secure/get-withdraw-charge-amount",
      providesTags: ["Withdraw"],
    }),
    getWithdrawCharge: builder.query({
      query: () => "/secure/get-withdraw-charge",
      providesTags: ["Withdraw"],
    }),
    withdrawHistory: builder.query({
      query: ({ page, limit }) =>
        `/secure/withdrawals?page=${page}&limit=${limit}`,
      providesTags: ["Withdraw"],
    }),
    withdraw: builder.mutation({
      query: (body) => ({
        url: "/secure/withdraw",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Withdraw"],
    }),
    getBank: builder.query({
      query: () => "/secure/fetch_payment_details",
      providesTags: ["Bank"],
    }),
    getUPI: builder.query({
      query: () => "/secure/fetch_upi",
      providesTags: ["Bank"],
    }),
    getTaskProfile: builder.query({
      query: (term) => `/common/get_profile_data?searchTerm=${term}`,
    }),
    getTaskSubProfile: builder.query({
      query: (query) =>
        `/common/get_sub_profile_data?searchTerm=${query?.term}&profile=${query?.profile}`,
    }),
    getPromotionalLink: builder.query({
      query: () => "/secure/get_dynamic_promotional_link",
      providesTags: ["Promotional Link"],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "/secure/change_password",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Profile"],
    }),
    getMyCreatedLeads: builder.query({
      query: () => "/secure/my-created-lead",
      providesTags: ["Lead"],
    }),
    addLead: builder.mutation({
      query: (body) => ({
        url: "/secure/lead",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Lead"],
    }),
    getTaskStatusCount: builder.query({
      query: ({ page, limit, status }) =>
        `/secure/get_task_status_count?page=${page}&limit=${limit}${
          status && `&status=${status}`
        }`,
      providesTags: ["Task"],
    }),

    submitCoupon: builder.mutation({
      query: (body) => ({
        url: "/secure/submitCoupon",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Point"],
    }),
    submitPremiumPurchase: builder.mutation({
      query: (data) => ({
        url: "/secure/submit_premium",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premiumPurchase"],
    }),
    getPopupImage: builder.query({
      query: () => "/common/get_popup_image",
      providesTags: ["Task"],
    }),

    getPopUp:builder.query({
      query:()=>({
        url:"/getPopUp/Website",
        method:"GET",
        
      })
    }),
    getAvailableSocialMediaTasksPointCountV2: builder.query({
      query: ({ page, limit, task_profile, searchTerm }) =>
        `/secure/get-available-social-media-tasks-point-count-v2?page=${page}&limit=${limit}&task_profile=${task_profile}&searchTerm=${searchTerm}`,
      providesTags: ["Available Social Media Tasks"],
    }),
  }),
});

export const {
  useLoginMutation,
  useDeleteAccountMutation,
  useGetValidateEmailQuery,
  useAddGoogleLoginMutation,
  useGetLoginUserQuery,
  useAddLoginWithMobileMutation,
  useFindMobileNumberOnDatabaseQuery,
  useSendOtpMutation,
  useCheckOtpMatchMutation,
  useForgetPasswordWithMobileMutation,
  useSendEmailOtpMutation,
  useCheckEmailOtpMatchMutation,
  useAddResetPassMutation,
  useGetManageSliderQuery,
  useMyProfileQuery,
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useAddBankMutation,
  useFetchBankQuery,
  useDeleteBankCardMutation,
  useDeleteBankMutation,
  useUpdateBankMutation,
  useAddUPIMutation,

  useGetValidateSponsorIdQuery,
  useEditImageMutation,
  useUpdateProfileMutation,
  useChangeEmailMutation,
  useSendOtpSecureMutation,
  useVerifyMobileMutation,
  useSetDOBMutation,
  useRegisterMutation,
  useGetValidateUsernameQuery,
  useGetValidateMobileQuery,
  useAddMobileRegisterMutation,
  useAllLeaderBoardHistoryQuery,
  useGetLeaderBoardUserInfoQuery,
  useDailyLeaderBoardQuery,
  useWeeklyLeaderBoardQuery,
  useMonthlyLeaderBoardQuery,
  useMyTotalReferralsQuery,
  useSelfEarningQuery,
  useReferralEarningQuery,
  useRewardIncomeQuery,
  useAddKycMutation,
  useUploadKYCMutation,
  useGetKycQuery,
  useGetVerifiedKycQuery,
  useGetAvailableSocialMediaTasksQuery,
  useGetAllMySMTHistoryQuery,
  useGetTotalAvailableSMTQuery,
  useGetSubmittedSMTQuery,
  useSubmitSocialMediaTaskMutation,
  useLazyGetPaymentQuery,
  useGetPaymentInfoQuery,
  useSubmitPremiumPaymentProofMutation,
  useSubmitPremiumPlusPaymentProofMutation,
  useSubmitPremiumPurchaseMutation,
  useGetNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useGetCustomNewsForAllQuery,
  useGetlatestNewsForUserQuery,
  useGetMyReferralsQuery,
  useClaimNowMutation,
  useGetMyPointHistoryQuery,
  useGetRedeemConversionRatioQuery,
  useSubmitRedeemMutation,
  useGetAllMysteryBoxQuery,
  useGetMinimumWithdrawAmountQuery,
  useGetWithdrawChargeAmountQuery,
  useGetWithdrawChargeQuery,
  useWithdrawHistoryQuery,
  useWithdrawMutation,
  useGetBankQuery,
  useGetUPIQuery,
  useGetTaskProfileQuery,
  useGetTaskSubProfileQuery,
  useGetPromotionalLinkQuery,
  useChangePasswordMutation,
  useGetMyCreatedLeadsQuery,
  useAddLeadMutation,
  useGetTaskStatusCountQuery,
  useSubmitCouponMutation,
  useGetPopupImageQuery,
  useGetPopUpQuery,
  useSendEmailForgotOtpMutation,
  useGetAvailableSocialMediaTasksPointCountV2Query,
} = userApi;
